const modal = document.querySelector(".js-modal");
const modalClose = document.querySelectorAll(".js-modal__close");

const init = () => {
	if (modal && modalClose) {
		modal.classList.add("c-modal--active");
		[...modalClose].forEach((btn) => btn.addEventListener("click", closeModal));
	}
};

const closeModal = () => {
	modal.classList.remove("c-modal--active");
};

export { init };
