import OcmMessenger from "ocm_mercurius_messenger";
import { messengerPublicationTypes } from "@ocm/services/services.constants.js";
import stickyHeader from "@sharedJS/stickyHeader";

let $header;
let $headerNav;
const defaultFixedHeaderHeight = 50;

const init = function () {
	// todo: newheader -> pageType anders ermitteln (siehe auch scss)

	$(".js-nav-login-button").on("click", (e) => {
		const _selectedLayerName = $("#master-common-layer").data("layerselected") || null;
		if (_selectedLayerName) {
			e.preventDefault();
			OcmMessenger.publish(messengerPublicationTypes.REQUEST_LOGIN, {
				isClosable: true,
			});
		}
	});

	$header = $(".js-header");
	$headerNav = $(".js-header-nav");

	stickyHeader.setHelperClasses();

	// Publish FixedNavBarHeight for sticky elements
	OcmMessenger.publish(messengerPublicationTypes.FIXED_HEADER_HEIGHT_CHANGED, {
		fixedHeaderHeight: getFixedHeaderHeight(),
	});
	const navContainer = document.querySelector(".js-primary-nav-container");
	if (navContainer) {
		navContainer.addEventListener("mouseenter", () => {
			document.querySelector(".js-primary-nav-toggle").classList.add("is-open");
		});
		navContainer.addEventListener("mouseleave", () => {
			document.querySelector(".js-primary-nav-toggle").classList.remove("is-open");
		});
	}
};

// todo: newheader einen dynamischen Wert ermitteln ACHTUNG nav ist gerade noch absolut plaziert  !!!!!!!
const naviTop = () => ($header ? $header.outerHeight() : 96);

const getFixedHeaderHeight = () => ($headerNav ? $headerNav.outerHeight() : defaultFixedHeaderHeight);

const header = {
	init,
	getFixedHeaderHeight,
	naviTop,
};

export default header;
// todo: newheader
